import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Statuspage } from 'statuspage.io';
import { AuthServiceService } from 'src/app/modules/authentication/services/auth-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year = new Date()?.getFullYear();
  appVersion = environment.appConfig.version;
  isDashboardRoute = false;
  appRunningStatus = '';
  constructor(private router: Router) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.isDashboardRoute = event.url.includes('/dashboard') ? true : false;
      }
    });
  }

  ngOnInit(): void {
    const statuspage = new Statuspage('c4h4x9x7hzk0');
    statuspage.api.getComponents().then((response) => {
      this.appRunningStatus = this.statusChecker(response);
    });
  }

  statusChecker(response: any) {
    let appStatus = '';
    for (let i = 0; i < response.components.length; i++) {
      if (response.components[i].id === 'fd6phrb801rc') {
        appStatus = response.components[i].status;
        this.applicationStatus(appStatus);
        break;
      }
    }
    return appStatus;
  }

  getStatusMaintenance() {
    return this.appRunningStatus;
  }

  applicationStatus(data: string): void {
    if (data == 'under_maintenance') {
      this.router.navigate(['/auth/under-maintenance']);
    }
  }
}
