import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appSpaceHandle]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SpaceHandleDirective,
      multi: true,
    },
  ],
})
export class SpaceHandleDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: boolean } | null {
    //const regex = /^(\s+\S+\s*)*(?!\s).*$/;
    const regex = /^(?:(?:\s*\S+\s*)*\n?)*$/;
    if (control.value && !regex.test(control.value)) {
      return { customValidator: true };
    }
    return null;
  }
}
