import { HttpContextToken } from '@angular/common/http';
import { SafeResourceUrl } from '@angular/platform-browser';

export interface Pagination {
  limit: number;
  currentPage: number;
  totalCount: number;
  totalPage: number;
  sort?: string;
}

export interface paginationEventresponse {
  currentPage?: number;
  page?: number;
  limit?: number;
  sort?: string;
  query?: string;
  totalCount?: number;
  totalPage?: number;
  offset?: number;
}

export interface TableSort {
  column: string;
  order: 'ASC' | 'DESC';
}

export interface groupInterface {
  id: string;
}

export interface contentGroupInterface {
  contentGroup: [];
}


export enum AlertEventType {
  AlertGenerated = 'AlertGenerated',
}

export interface Count {
  count: number;
}

export interface IAlertSeverty {
  severity0: number;
  severity1: number;
  severity2: number;
  severity3: number;
  severity4: number;
}

export interface IAlertReported {
  type: string;
  count: number;
}

export enum NotesTypes {
  Vitals = 'Vitals',
  Medications = 'Medications',
  Alerts = 'Alerts',
  Task = 'Task',
  Self = 'Self',
  User = 'User',
  ReachTrial = 'ReachTrial',
}

export interface DemographicData {
  userID: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  imageUrl: string;
  sex: string;
}

export interface FileUploadSuccess {
  url: string;
  mimetype: string;
  documentId: string;
}

export interface FilterOptions {
  id: string;
  name: string;
  checked?: boolean;
}

export interface GenericTable {
  value: unknown;
  routeUrl: string;
  queryParams: object;
}
export interface GenericTableColumn {
  key: string;
  title: string;
  displayKey: string;
  filterKey?: string;
  searchKey?: string;
  sort?: boolean;
  search?: {
    icon: boolean;
    active: boolean;
  };
  filter?: {
    icon: boolean;
    active: boolean;
  };
  hasLink?: boolean;
  icons?: Array<{ iName: string; key: string; class: string; show: boolean; route?: string }>;
}

export interface FilterOptions {
  id: string;
  name: string;
  checked?: boolean;
}

export interface ConfirmationDialogData {
  title: string;
  message: string;
  acceptButtonText?: string;
  declineButtonText?: string;
}

export interface registerFormGrp {
  userName: string;
  password: string;
  // suffix: string;
  firstName: string;
  lastName: string;
  sex: string;
  phone?: string;
  emailAddress?: string;
  affiliationCode: string;
  invitationId: string;
  // npi?: string;
}

export interface ChartResp {
  anomaly: [
    {
      lower: string;
      timestamp: string;
      upper: string;
      value: string;
    },
  ];
  trends: [
    {
      avgValue: string;
      countOfRecords: string;
      dataField: string;
      maxValue: string;
      minValue: string;
      timestamp: string;
      userId: string;
    },
  ];
}
export interface Attachment {
  type: string;
  name?: string;
  mimeType?: string;
  url: string;
  safeUrl?: SafeResourceUrl;
  icon?: string;
}
export interface TemporaryFileUrl {
  url: string;
}
export const HIDE_LOADER = new HttpContextToken<boolean>(() => false);
