import { Pipe, PipeTransform } from '@angular/core';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';

@Pipe({
  name: 'clientLabel',
})
export class ClientLabelPipe implements PipeTransform {
  constructor(private permissionsService: PermissionsService, private translatePipe: TranslatePipe) {}
  transform(value: string): unknown {
    const userConfigurations = this.permissionsService.getUserConfigurations();
    const clientLabel = userConfigurations.clientLabel || { managerUser: '', managedUser: '' };
    if (value && value.toLowerCase() === 'manager') {
      if (clientLabel.managerUser) {
        value = this.translatePipe.transform(clientLabel.managerUser);
      }
    } else {
      if (clientLabel.managedUser) {
        value = this.translatePipe.transform(clientLabel.managedUser);
      }
    }
    return this.translatePipe.transform(value) || value;
  }
}
