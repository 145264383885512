import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTime',
  pure: true,
})
export class DateTimePipe implements PipeTransform {
  private currentDate = new Date();

  /**
   * Format given date to Date string separated by provided separator.
   * 'Today' and 'Yesterday' will be output for Date accordingly.
   * @param date - Given date to be formatted
   * @param separator - Separator used to separate date and time. Note - spaces needs to be added to separator string as per requirement
   * @returns - formatted Date string
   */
  transform(date: string | Date, separator: string): string {
    const dateObj = new Date(date);
    const localeTime: string = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    if (
      dateObj.getMonth() === this.currentDate.getMonth() &&
      dateObj.getFullYear() === this.currentDate.getFullYear()
    ) {
      if (dateObj.getDate() === this.currentDate.getDate()) {
        return `Today${separator}${localeTime}`;
      } else if (dateObj.getDate() === this.currentDate.getDate() - 1) {
        return `Yesterday${separator}${localeTime}`;
      }
    }

    return `${dateObj.toLocaleDateString()}${separator}${localeTime}`;
  }
}
