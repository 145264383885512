import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  
  FormBuilder,
  FormGroup,
  
  Validators,
  
} from "@angular/forms";
import moment from "moment-timezone";
import { DatePipe } from "@angular/common";
@Component({
  selector: 'app-dob',
  templateUrl: './dob.component.html',
  styleUrls: ['./dob.component.scss']
})
export class DobComponent implements OnChanges {
 
  @Input() dob:string | undefined='';
  mm: string | null ='';
  dd: string | null= "";
  yyyy: string | null= "";
  updateDate = "";
  form: FormGroup;
  errMsg = false;
  @Output() updateDob: EventEmitter<string> = new EventEmitter<string>();
  @Output() month: EventEmitter<string> = new EventEmitter<string>();
  @Output() date: EventEmitter<string> = new EventEmitter<string>();
  @Output() year: EventEmitter<string> = new EventEmitter<string>();
  @Output() errorCode: EventEmitter<boolean> = new EventEmitter<boolean>();
  finalError = false;
 
  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {

    this.form = this.formBuilder.group({
      dobMonth: ['', [Validators.required]],
      dobDay: ['', [Validators.required]],
      dobYear: ['', [Validators.required, Validators.pattern("(19[0-9][0-9]|20[0-9][0-9]|2010)")]],
    })
   
   }

  
   ngOnChanges(){
    // console.log(this.dob)
     
     if(this.dob){
      this.mm = this.datePipe.transform(this.dob, 'MM');
      this.dd= this.datePipe.transform(this.dob, 'dd');
      this.yyyy= this.datePipe.transform(this.dob, 'yyyy');
      this.form.patchValue({
        dobMonth:this.mm,
        dobDay:this.dd,
        dobYear:this.yyyy
       })
      this.updateDate = this.mm+"-"+this.dd+"-"+this.yyyy;
     }
  }

  
  changeMonth(mm:string|null){
     this.mm = ('0' + mm).slice(-2);
     this.updateDate = this.mm+"-"+this.dd+"-"+this.yyyy;
     this.updateDob.emit(this.updateDate);
   } 
  changeDate(dd:string|null){
    this.dd = ('0' + dd).slice(-2) ;
    this.updateDate = this.mm+"-"+this.dd+"-"+this.yyyy;
    this.updateDob.emit(this.updateDate);
  } 

  changeYear(yyyy:string | null){
     this.yyyy = yyyy;
     this.updateDate = this.mm+"-"+this.dd+"-"+this.yyyy;
     this.updateDob.emit(this.updateDate);
  }

  delegateBox(birthYear: string, month: string, day: string) {  // Care Provider Information 18 < age check 
    
    month.toString().length == 1 ? month = '0' + month : month;
    const birth = birthYear + "-" + month + "-" + day;
    const ageMS = Date.parse(Date()) - Date.parse(birth);
    const age = new Date();
    age.setTime(ageMS);
    const ageYear = age.getFullYear() - 1970;
    if (moment(birth).isValid() == false || ageYear < 0) {
      this.errMsg = true;
    }
    else {
      this.errMsg = false;
    }
    //console.log(birth,'bbbbbbbbbbbbbbbbbbb', moment(birth).isValid())
    if(birth==='--'){
     this.finalError =false;
     this.updateDate="";
     this.updateDob.emit(this.updateDate);
    }else{
      
    if(this.form.controls['dobDay'].hasError('required') || this.form.controls['dobYear'].hasError('required') || this.form.controls['dobMonth'].hasError('required')){
      this.finalError =  true;
    }else{
      this.finalError = (((this.form.controls['dobDay'].touched ) && this.form.controls['dobDay'].hasError('required')) ||
      ((this.form.controls['dobMonth'].touched ) && this.form.controls['dobMonth'].hasError('required')) ||
      ((this.form.controls['dobYear'].touched ) && this.form.controls['dobYear'].hasError('required')) ||
      this.form.controls['dobYear'].hasError('pattern') ||
      this.errMsg);
    }
   //console.log(this.finalError,'99999999999999999999')
    }
    //console.log(this.finalError,'rrrrrrrrrrrrrrrrrrrrr')
    this.errorCode.emit(this.finalError);
  }
}
