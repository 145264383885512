import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { AuthServiceService } from 'src/app/modules/authentication/services/auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard  {
  constructor(private _authService: AuthServiceService, private router: Router, private location: Location) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._authService.isAuthorized() && this._authService.getAllowDashboardAccess()) {
      this.router.navigate(['/dashboard']);
      return false;
    }
    return true;
  }
}
