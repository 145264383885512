import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ProfileData } from 'src/app/modules/shared/interfaces/profileData.interface';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { registerFormGrp } from '../../shared/interfaces/common.entities';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceService {
  authUrl: string = environment.url.apiAuth;
  baseUrl: string = environment.url.apiHost;
  apiVersion: string = environment.url.version;
  refreshTokenTimerInterval!: ReturnType<typeof setInterval>;
  syncVerification = new Subject();

  constructor(
    private httpService: HttpService,
    private router: Router,
  ) {}

  login(body: any, headers?: any): Observable<any> {
    const url = `${this.authUrl}/auth/application-login`;
    return this.httpService.post(url, body, { headers: headers }).pipe();
  }
  register(body: registerFormGrp): Observable<registerFormGrp> {
    const url = `${this.baseUrl}${this.apiVersion}/auth/register`;
    return this.httpService.post(url, body).pipe();
  }
  activateUser(body: any, id: string): Observable<any> {
    const url = this.baseUrl + this.apiVersion + '/user/activate/' + id;
    return this.httpService.patch(url, body);
  }
  forgotPassword(body: any): Observable<any> {
    const url = this.baseUrl + this.apiVersion + '/auth/forgotPassword';
    return this.httpService.post(url, body);
  }
  resetPassword(body: any): Observable<any> {
    const url = this.baseUrl + this.apiVersion + '/auth/resetPassword';
    return this.httpService.patch(url, body);
  }
  updatePassword(body: any): Observable<any> {
    const url = this.baseUrl + this.apiVersion + '/auth/updatePassword';
    return this.httpService.patch(url, body);
  }
  fetchDisclaimers(isSigned: boolean) {
    const url =
      this.baseUrl + this.apiVersion + '/disclaimer?signed=' + isSigned;
    return this.httpService.get(url);
  }
  acceptDisclaimerById(id: string) {
    const url = this.baseUrl + this.apiVersion + `/disclaimer/${id}/Accepted`;
    return this.httpService.patch(url);
  }
  declineDisclaimerById(id: string) {
    const url = this.baseUrl + this.apiVersion + `/disclaimer/${id}/Rejected`;
    return this.httpService.patch(url);
  }
  logOut() {
    const url = `${this.authUrl}/auth/logout`;
    return this.httpService.get(url);
  }
  refreshToken(body: any, headers: any) {
    const url = `${this.authUrl}/auth/token`;
    return this.httpService.post(url, body, headers, true);
  }

  getAffiliationCodeDetails(affiliationCode: string) {
    const url = `${this.baseUrl}${this.apiVersion}/affiliation/detail/${affiliationCode}`;
    return this.httpService.get(url);
  }

  /* Fetch user configurations  */
  fetchUserConfigurations() {
    const url = `${this.baseUrl}${this.apiVersion}/user/configuration`;
    return this.httpService.get(url);
  }
  setRoles(roles: Array<any>) {
    if (roles?.length) {
      localStorage.setItem('roles', JSON.stringify(roles));
    }
  }
  setUserName(userName: string) {
    if (userName) {
      localStorage.setItem('userName', userName);
    }
  }
  setUserId(userId: string) {
    if (userId) {
      localStorage.setItem('userId', userId);
    }
  }
  setClientId(clientId: string) {
    if (clientId) {
      localStorage.setItem('clientId', clientId);
    }
  }
  setClientDetails(client: { name: string; _id: string; id?: string }) {
    if (client && client?._id) {
      localStorage.setItem('client', JSON.stringify(client));
    }
  }
  setUserDemographic(userDemographicO: ProfileData) {
    if (userDemographicO) {
      localStorage.setItem('demographicData', JSON.stringify(userDemographicO));
    }
  }

  setAllowDashboardAccess(access: boolean) {
    localStorage.setItem('allowDashboardAccess', access ? 'true' : 'false');
  }
  setOAuthTokens(__tokens: any) {
    if (__tokens) {
      this.removeOAuthTokens();
      this.removeVerificationToken();
      localStorage.setItem(
        'access_token',
        __tokens.access_token ? __tokens.access_token : '',
      );
      localStorage.setItem(
        'refresh_token',
        __tokens.refresh_token ? __tokens.refresh_token : '',
      );
      localStorage.setItem(
        'access_token_expires_in_minutes',
        __tokens.access_token_expires_in_minutes
          ? __tokens.access_token_expires_in_minutes
          : '',
      );
      this.setRoles(__tokens?.principal?.authorities?.roles || '');
      this.setUserName(__tokens?.principal?.userName || '');
      this.setUserId(__tokens?.principal?.sub || '');
      //this.setClientId(__tokens?.authenticatedFor?.client?._id || '');
      this.setClientDetails(__tokens.authenticatedFor.client);
    }
  }

  removeOAuthTokens() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }
  getOAuthAccessToken(): string {
    return localStorage.getItem('access_token') || '';
  }
  getToken(): string {
    return localStorage.getItem('access_token') || '';
  }
  getRoles() {
    const roles = localStorage.getItem('roles');
    return roles ? JSON.parse(roles) : [];
  }
  getUserName(): string {
    return localStorage.getItem('userName') || '';
  }
  getLoggedInUserFullName(): string {
    const demographicData = this.getUserDemographic();
    return demographicData?.userID
      ? `${demographicData.firstName} ${demographicData.lastName}`
      : '';
  }
  getUserId(): string {
    return localStorage.getItem('userId') || '';
  }
  getUserAcsId(): string {
    const configurations = localStorage.getItem('userConfigurations');
    return configurations ? JSON.parse(configurations).userAcsId : '';
  }

  getUserConfiguration() {
    const configurations = localStorage.getItem('userConfigurations');
    return configurations ? JSON.parse(configurations) : '';
  }
  getClientId(): string {
    return this.getClientDetails()?._id || '';
  }
  getClientName(): string {
    return this.getClientDetails()?.name || '';
  }
  getClientDetails() {
    const client = localStorage.getItem('client');
    return client ? JSON.parse(client) : null;
  }
  getUserDemographic() {
    const demographicData = localStorage.getItem('demographicData');
    return demographicData ? JSON.parse(demographicData) : null;
  }

  getAllowDashboardAccess() {
    return localStorage.getItem('allowDashboardAccess') &&
      localStorage.getItem('allowDashboardAccess')?.toLowerCase() == 'true'
      ? true
      : false;
  }
  isAuthorized() {
    return this.getOAuthAccessToken() ? true : false;
  }
  logoutCallback() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
  signOut() {
    if (!this.isAuthorized()) {
      this.logoutCallback();
      return;
    }
    this.logOut().subscribe({
      next: (res) => {
        if (res) {
          this.logoutCallback();
        }
      },
      error: (err) => {
        if (err) {
          this.logoutCallback();
        }
      },
    });
  }
  startRefreshTokenTimer() {
    this.refreshTokenTimerInterval = setInterval(() => {
      if (this.isAuthorized()) {
        const refresh_token_timer_in_seconds = Number(
          localStorage.getItem('refresh_token_timer_in_seconds'),
        );
        const access_token_expires_in_minutes =
          Number(localStorage.getItem('access_token_expires_in_minutes')) * 60;
        let timer =
          refresh_token_timer_in_seconds > 0
            ? refresh_token_timer_in_seconds
            : access_token_expires_in_minutes;
        timer = timer - 1;
        localStorage.setItem('refresh_token_timer_in_seconds', String(timer));

        if (timer <= 60) {
          this.fetchRefreshToken();
          this.clearRefreshTokenTimerInterval();
        }
      }
    }, 1000);
  }
  clearRefreshTokenTimerInterval() {
    if (this.refreshTokenTimerInterval) {
      clearInterval(this.refreshTokenTimerInterval);
    }
  }
  fetchRefreshToken() {
    const body = new URLSearchParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const refresh_token = localStorage.getItem('refresh_token') || '';
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refresh_token);
    if (refresh_token) {
      this.refreshToken(body, { headers: headers }).subscribe(
        (response) => {
          this.clearRefreshTokenTimerInterval();
          localStorage.removeItem('refresh_token_timer_in_seconds');
          this.startRefreshTokenTimer();
          this.setOAuthTokens(response);
        },
        (error) => {
          if (error) {
            // this.toastr.error(error.error.message, error.error.error);
            //this.signOut();
          }
        },
      );
    }
  }
  // Verification related methods
  setVerificationToken(_token: any) {
    if (_token) {
      this.removeOAuthTokens();
      localStorage.setItem('verification_token', _token.token);
      localStorage.setItem('token_type', _token.token_type);
      localStorage.setItem(
        'verificationInfo',
        JSON.stringify({
          mobilePhone: _token.mobilePhone,
          emailAddress: _token.emailAddress,
        }),
      );
    }
  }
  getVerificationToken(): string {
    return localStorage.getItem('verification_token') || '';
  }
  getTokenType(): string {
    return localStorage.getItem('token_type') || '';
  }
  getVerificationInfo(): any {
    return JSON.parse(localStorage.getItem('verificationInfo') || '');
  }
  removeVerificationToken() {
    localStorage.removeItem('verification_token');
    localStorage.removeItem('token_type');
    localStorage.removeItem('verificationInfo');
  }
  forVerification() {
    return this.getVerificationToken() ? true : false;
  }
  requestVerification(body: any) {
    const url = `${this.authUrl}/auth/tfa`;
    return this.httpService.post(url, body);
  }

  getAppRedirectUri(reqAppName: string) {
    const appredirectUriList = environment.redirectUris;
    const result = appredirectUriList.find(
      ({ appName }) => appName === reqAppName,
    );
    //check if the app is mobile only and opened from desktop browser then dont redirect it.
    const platorm = result?.platorm;
    if (platorm === 'mobileOnly' && !this.isOpenOnMobileBrowser()) {
      return '';
    }
    return result?.uri;
  }

  isOpenOnMobileBrowser() {
    const regex =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }
}
