import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, searchKey?: string) {
    if (!items) return [];
    if (!searchText) return items;
    if (searchKey) {
      return items.filter((items) => {
        return String(items[searchKey]).toLowerCase().includes(searchText.toLowerCase());
      });
    } else {
      return items.filter((item) => {
        return Object.keys(item).some((key) => {
          return String(item[key]).toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }
  }
}
