import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'filterPopUp',
  pure: true,
})
export class FilterPopUpPipe implements PipeTransform {
  transform(items: unknown[], filterData: string, isformGrp = false): any[] {
    if (!items) {
      items = [];
      return items;
    }
    if (!filterData) {
      return items;
    }
    filterData = filterData.toString().toLowerCase();

    if (isformGrp) {
      const temp: Array<FormGroup> = items as Array<FormGroup>;
      return temp.filter((ele) => {
        return ele.value.name.toLowerCase().includes(filterData);
      });
    } else {
      return items.filter((it: any) => {
        if (it?.userName) {
          return it?.userName.toLowerCase().includes(filterData);
        }
        if (it?.name) {
          return it?.name.toLowerCase().includes(filterData);
        }
      });
    }
  }
}
