import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, Output , EventEmitter, OnDestroy} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
@Directive({
  selector: '[clickOutSide]'
})
export class ClickOutSideDirective implements AfterViewInit, OnDestroy {
  @Output() clickedOutSide = new EventEmitter<void>();
  outsideClickSubscription!: Subscription;
  constructor(private element: ElementRef, @Inject(DOCUMENT) private document: Document) { }

  ngAfterViewInit(): void {
    this.outsideClickSubscription = fromEvent(this.document, "click")
      .pipe(
        filter((elem: any) => {
        return !this.isInside(elem?.target as HTMLElement);
      })
    ).subscribe( () => {
      this.clickedOutSide.emit();
    })
  }
  isInside(elementToCheck: HTMLElement): boolean {
    return elementToCheck === this.element.nativeElement || this.element.nativeElement.contains(elementToCheck);
  }
  ngOnDestroy() {
    this.outsideClickSubscription.unsubscribe();
  }
}

