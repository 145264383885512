import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HsNoAccessComponent } from './components/hs-no-access/hs-no-access.component';
import { PermissionsService } from './services/permissions.service';
import { PermissionsDirective } from './directives/permissions.directive';
import { PipesModule } from '../shared/pipes/pipes.module';

@NgModule({
  declarations: [HsNoAccessComponent, PermissionsDirective],
  providers: [PermissionsService],
  imports: [CommonModule, PipesModule],
  exports: [PermissionsDirective],
})
export class PermissionsModule {
  public getComponent() {
    return HsNoAccessComponent;
  }
}
