import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from 'src/app/modules/authentication/services/auth-service.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private authService: AuthServiceService) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.headers.has('Content-Type')) {
      if (!(request.body instanceof FormData && request.body.get('file'))) {
        request = request.clone({
          setHeaders: { 'Content-Type': 'application/json' },
        });
      }
    }
    if (this.authService.isAuthorized()) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.authService.getToken()),
      });
    }

    if (this.authService.forVerification()) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.authService.getVerificationToken()),
      });
    }
    request = request.clone({
      headers: request.headers.set('app_name', environment.appConfig.name),
    });
    request = request.clone({
      headers: request.headers.set('accept-language', localStorage.getItem('NG_TRANSLATE_LANG_KEY') || 'en'),
    });
    request = request.clone({
      headers: request.headers.set('timezoneoffset', new Date().getTimezoneOffset().toString()),
    });
    request = request.clone({
      headers: request.headers.set('timezonename', Intl.DateTimeFormat().resolvedOptions().timeZone || ''),
    });
    request = request.clone({
      headers: request.headers.set('os', 'web'),
    });

    return next.handle(request).pipe(timeout(300000));
  }
}
