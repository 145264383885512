import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CssService {
  public windowSize$: BehaviorSubject<string>;
  #sm = 576;
  #md = 768;
  #lg = 992;
  #xl = 1200;
  #xxl = 1400;

  #windowWidth = window.innerWidth;

  constructor() {
    this.windowSize$ = new BehaviorSubject(this.sizeCategory());
  }

  public windowResized(): void {
    this.windowSize$.next(this.sizeCategory());
  }

  public isSM(): boolean {
    if (this.#windowWidth < this.#sm) return true;
    return false;
  }

  public isMD(): boolean {
    if (this.#windowWidth < this.#md) return true;
    return false;
  }

  public isLG(): boolean {
    if (this.#windowWidth < this.#lg) return true;
    return false;
  }

  public isXL(): boolean {
    if (this.#windowWidth < this.#xl) return true;
    return false;
  }

  public isXXL(): boolean {
    if (this.#windowWidth < this.#xxl) return true;
    return false;
  }

  private sizeCategory(): string {
    const windowWidth: number = window.innerWidth;
    if (windowWidth < this.#sm) return 'sm';
    else if (windowWidth >= this.#sm && windowWidth < this.#md) return 'md';
    else if (windowWidth >= this.#md && windowWidth < this.#lg) return 'lg';
    else if (windowWidth >= this.#lg && windowWidth < this.#xl) return 'xl';
    return 'xxl';
  }
}
