import { Directive, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from 'src/app/modules/permissions/services/permissions.service';

@Directive({
  selector: '[appPermissions]',
})
export class PermissionsDirective {
  private hasView = false;
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private _permissionsService: PermissionsService,
    private renderer: Renderer2,
  ) {}
  @Input() set appPermissions(condition: string) {
    if (this._permissionsService.hasPermission(condition) && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!this._permissionsService.hasPermission(condition) && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
  @Input() set appPermissionsDisabled(condition: string) {
    if (!this._permissionsService.hasPermission(condition)) {
      this.renderer.setAttribute(this.templateRef.elementRef.nativeElement.previousSibling, 'disabled', 'true');
      this.renderer.addClass(this.templateRef.elementRef.nativeElement.previousSibling, 'disabled');
    }
  }
}
