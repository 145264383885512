import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  getUserConfigurations() {
    const userConfigurations = localStorage.getItem('userConfigurations');
    return userConfigurations ? JSON.parse(userConfigurations) : null;
  }
  getUserPermissions() {
    return this.getUserConfigurations() && this.getUserConfigurations().permissionSettings;
  }
  getSelfPermissions() {
    return (this.getUserConfigurations() && this.getUserConfigurations().permissionSettings?.self) || [];
  }
  getOthersPermissions() {
    return (this.getUserConfigurations() && this.getUserConfigurations().permissionSettings?.others) || [];
  }
  getPermissionsScopes(permissions: IPermission[]) {
    let scopes: string[] = [];
    if (permissions) {
      scopes = permissions.map((permission) => permission.scope);
    }
    return scopes;
  }
  hasPermission(permission: string) {
    let permissionAccess = false;
    let permissionsList: IPermission[] = [];
    if (permission) {
      const permissionsA = permission.split('.');
      if (permissionsA.length) {
        if (permissionsA[0].toLowerCase() === 'self') {
          permissionsList = this.getSelfPermissions();
        } else if (permissionsA[0].toLowerCase() === 'ai') {
          permissionsList = this.getAIPermissionSettings();
        } else {
          permissionsList = this.getOthersPermissions();
        }
        const scopes = this.getPermissionsScopes(permissionsList);
        if (scopes?.length && scopes.indexOf(permissionsA[1]) >= 0) {
          if (permissionsList[scopes.indexOf(permissionsA[1])]?.permissions.indexOf(permissionsA[2]) >= 0) {
            permissionAccess = true;
          }
        }
      }
    }
    return permissionAccess;
  }

  private getAIPermissionSettings(): IPermission[] {
    const permissionList: IPermission[] = [];
    if (this.getUserConfigurations().aiSettings.genAiPatientSummarization) {
      permissionList.push({
        scope: 'PatientSummarization',
        label: 'Patient Summarization',
        permissions: ['Read'],
      });
    }
    if (this.getUserConfigurations().aiSettings.genAiSuggestedHcpReplies) {
      permissionList.push({
        scope: 'SuggestedHcpReplies',
        label: 'Suggested Hcp Replies',
        permissions: ['Read'],
      });
    }
    return permissionList;
  }
}
export interface IPermission {
  label?: string;
  permissions: Array<string>;
  scope: string;
}
