import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { AuthServiceService } from 'src/app/modules/authentication/services/auth-service.service';
import { HIDE_LOADER } from '../interfaces/common.entities';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private http: HttpService,
    private authService: AuthServiceService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.http.loader.next(false);
          this.showErrors(error, request);
          return throwError(() => error);
        }),
      )
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (
            evt instanceof HttpResponse &&
            request.context.get(HIDE_LOADER) === false
          ) {
            this.http.loader.next(false);
          }
          return evt;
        }),
      );
  }
  showErrors(error: HttpErrorResponse, request: HttpRequest<unknown>) {
    let message = '';
    if (error) {
      switch (error.error.statusCode || error.status) {
        case 401:   
            message = error.error.message;
          break;
        case 500:
          message =
            'Internal Server Error. The request could not be completed.';
          break;
        case 403:
          if (error?.error?.message) {
            message = error?.error?.message;
          } else {
            message =
              "Forbidden Request, You don't have permission to access this";
          }
          break;
        case 404:
          if (error?.error?.message) {
            message = error?.error?.message;
          } else {
            message = 'Service not found';
          }
          break;
        default:
          message = error?.error?.message;
          break;
      }
      this.toastr.error(message, error.error.error);
    }
  }

  refreshToken(request: HttpRequest<unknown>, next: HttpHandler) {
    const body = new URLSearchParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const refresh_token = localStorage.getItem('refresh_token') || '';
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refresh_token);
    if (refresh_token) {
      this.authService
        .refreshToken(body, { headers: headers })
        .pipe(
          //retry(3),
          switchMap((response: any) => {
            this.authService.setOAuthTokens(response);
            return next.handle(request);
          }),
        )
        .subscribe({
          next: (response) => response,
          error: (error) => {
            if (error) {
              this.toastr.error(error.error.message, error.error.error);
              this.authService.signOut();
            }
          },
        });
    }
  }
}
