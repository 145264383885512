import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslatePipe } from './translate.pipe';
import { SortByPipe } from './sort-by.pipe';
import { DateTimePipe } from './date-time/date-time.pipe';
import { ClientLabelPipe } from './client-label.pipe';
import { FilterPipe } from './filter.pipe';
import { FilterPopUpPipe } from './filterPopUp.pipe';
import { CapitalizeFirstLetterPipe } from './capitalize-first-letter.pipe';

@NgModule({
  declarations: [
    TranslatePipe,
    SortByPipe,
    DateTimePipe,
    ClientLabelPipe,
    FilterPipe,
    FilterPopUpPipe,
    CapitalizeFirstLetterPipe,
  ],
  exports: [
    TranslatePipe,
    SortByPipe,
    DateTimePipe,
    ClientLabelPipe,
    FilterPipe,
    FilterPopUpPipe,
    CapitalizeFirstLetterPipe,
  ],
  providers: [TranslatePipe],
  imports: [CommonModule],
})
export class PipesModule {}
