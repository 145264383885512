export const environment = {
  production: false,
  name: 'develop',
  env: 'dev',
  //Webservice urls
  url: {
    apiAuth: 'https://oauth2.medocity.net',
    apiHost: 'https://api.medocity.net',
    version: '/v3',
  },
  //App specific settings
  appConfig: {
    name: 'generalweb',
    title: 'Medocity',
    type: 'general',
    version: '1.0.0',
  },
  redirectUris: [
    {
      appName: 'Site Portal',
      uri: 'https://portal.medocity.net',
      platorm: '',
    },
    {
      appName: 'My Care Joruney',
      uri: 'https://mycarejourney.page.link/open',
      platorm: 'mobileOnly',
    },
  ],
};
