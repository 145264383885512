import { Component, Input, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { PaginationOptions } from '../../interfaces/pagination-options';
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() paginationOptions!: PaginationOptions;
  @Output() pagination: EventEmitter<PaginationOptions> = new EventEmitter<PaginationOptions>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 15, 20];
  pageEvent!: PageEvent;
  constructor(private router: Router) {}

  ngOnChanges(): void {
    this.pageIndex = this.paginationOptions?.currentPage - 1 || 0;
  }
  handlePage(e: PageEvent): void {
    if (e.previousPageIndex && e.previousPageIndex > e.pageIndex) {
      this.paginationOptions.currentPage = e.pageIndex <= 0 ? 1 : e.pageIndex + 1;
    } else {
      this.paginationOptions.currentPage = e.pageIndex + 1;
    }
    this.pageIndex = this.paginationOptions.currentPage;
    this.paginationOptions.limit = e.pageSize;
    this.pagination.emit(this.paginationOptions);
  }
}
