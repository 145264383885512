// import { Directive, ElementRef, HostListener, Input } from '@angular/core';

// @Directive({
//   selector: '[appReadMore]'
// })
// export class ReadMoreDirective {
//   @Input('appReadMore') maxLength: number = 50;
//   private isShowMore = true;

//   constructor(private el: ElementRef) {}

 

//   @HostListener('click') onClick() {
//     this.isShowMore = !this.isShowMore;
//     this.toggleShowMore();
//   }

//   private toggleShowMore() {
//     const element = this.el.nativeElement;
//     const content = element.innerText;
//     if (this.isShowMore && content.length > this.maxLength) {
//       element.innerHTML = `${content.slice(0, this.maxLength)}... <a href="javascript:void(0);">Show more</a>`;
//     } else {
//       element.innerHTML = `${content} <a href="javascript:void(0);">Show less</a>`;
//     }
//   }
// }


import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appReadMore]'
})
export class ReadMoreDirective {
  @Input() readMoreText!: string;
  @Input() maxLength!: number;

  constructor(private elementRef: ElementRef) { }

  @HostListener('click') onClick() {
    this.toggleReadMore();
  }

  private toggleReadMore(): void {
    const element = this.elementRef.nativeElement as HTMLElement;
    const content = element.textContent || '';
    const shouldTruncate = content.length > this.maxLength;

    if (shouldTruncate) {
      const truncatedContent = element.textContent === content ? content.substr(0, this.maxLength) + '...' : content;
      element.textContent = truncatedContent;
    }
  }
}


