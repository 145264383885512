import { NgModule } from '@angular/core';
import { LoadChildrenCallback, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/shared/guards/auth.guard';
import { NotFoundComponent } from './modules/shared/component/not-found/not-found.component';
import { LoggedInGuard } from './modules/shared/guards/logged-in.guard';
const routes: Routes = [
  {
    path: '', //this wil load auth module if no route found
    redirectTo: '/auth/app-download',
    pathMatch: 'full',
  },

  // { path: 'login', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: 'app-download', redirectTo: 'auth/app-download', pathMatch: 'full' },
  {
    path: '', //this wil load auth module if no route found
    redirectTo: 'auth/resetPassword',
    pathMatch: 'full',
  },
  {
    path: 'activateaccount',
    redirectTo: 'auth/activateaccount',
    pathMatch: 'full',
  },
  {
    path: 'signup',
    redirectTo: 'auth/create-account',
    pathMatch: 'full',
  },
  {
    path: 'resetpassword',
    redirectTo: 'auth/resetPassword',
    pathMatch: 'full',
  },
  {
    path: 'verification',
    redirectTo: 'auth/verification',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('src/app/modules/authentication/auth.module').then(
        (m) => m.AuthModule,
      ),
    canActivate: [LoggedInGuard],
  },

  {
    //this is wild card route
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
