import { AfterContentChecked, ChangeDetectorRef, Component, HostListener, OnDestroy, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { HttpService } from 'src/app/modules/shared/services/http.service';
import { AuthServiceService } from 'src/app/modules/authentication/services/auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { CssService } from './modules/shared/services/css.service';
import { BackdropAndLoaderService } from './modules/shared/services/backdrop-and-loader.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentChecked, OnDestroy {
  title = '';
  favIcon: any;
  state = '';
  loading = false;
  token = '';
  isUnderMaintenancePage = false;
  public backdropAndLoaderService = inject(BackdropAndLoaderService);

  private timer: ReturnType<typeof setTimeout> = setTimeout(() => {
    // do nothing
  }, 0);

  #cssService = inject(CssService);

  constructor(
    private titleService: Title,
    private router: Router,
    private _authService: AuthServiceService,
    private chDef: ChangeDetectorRef,
    private http: HttpService,
    private toastr: ToastrService,
    private matDialoge: MatDialog,
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.isUnderMaintenancePage = event.url.includes('/under-maintenance') ? true : false;
      }
    });
    this.listenToWindowResize();
    this.setFavicon();
    this.setTitle(environment.appConfig.title);
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.http.loader.next(true);
        this.matDialoge.closeAll();
      }
      if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
        this.http.loader.next(false);
      }
    });
    this.http.loader.subscribe((res) => {
      this.loading = res;
    });
    this.startRefreshTokenTimer();
  }

  public ngAfterContentChecked(): void {
    this.loading = this.loading;
    this.token = this._authService.getToken() || '';
    this.chDef.detectChanges();
  }
  setTitle(title: string) {
    this.title = title;
    this.titleService.setTitle(this.title);
  }

  setFavicon() {
    this.favIcon = document.querySelector('#appIcon');
    this.favIcon.href = 'assets/images/favicon/' + environment.appConfig.name + '.png';
  }

  startRefreshTokenTimer() {
    this._authService.startRefreshTokenTimer();
  }

  public ngOnDestroy(): void {
    this._authService.clearRefreshTokenTimerInterval();
  }

  @HostListener('window:storage', ['$event'])
  public webStorageValueChanged(event: StorageEvent): void {
    if (event && event.oldValue && event.key === 'userConfigurations') {
      this._authService.signOut();
      this.toastr.error(
        'Website storage seems to have been tampered with. For security reasons, you have been logged out.',
      );
    }
  }

  private listenToWindowResize(): void {
    window.onresize = () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.#cssService.windowResized();
      }, 500);
    };
  }
}
