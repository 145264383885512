import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appCmsLinksHandler]',
})
export class CmsLinksHandlerDirective {
  @HostListener('click', ['$event'])
  handle(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.nodeName === 'A') {
      event.preventDefault();
      event.stopPropagation();
      const href = target.getAttribute('href') || '';
      window.open(href, '_blank');
    }
  }
}
