import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TranslateService {
  data: Record<string, string> = {};

  constructor(private http: HttpClient) {}

  use(lang: string): Promise<Record<string, string>> {
    return new Promise<Record<string, string>>((resolve) => {
      const langPath = `assets/i18n/${lang || 'en'}.json`;
      this.http.get<Record<string, string>>(langPath).subscribe({
        next: (translation) => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error: () => {
          this.data = {};
          resolve(this.data);
        },
      });
    });
  }
}
