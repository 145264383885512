import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PipesModule } from './pipes/pipes.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PermissionsModule } from '../permissions/permissions.module';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';
import { DobComponent } from './component/dob/dob.component';
import { PaginationComponent } from './component/pagination/pagination.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';

const matModules = [FeatherModule.pick(allIcons)];
@NgModule({
  declarations: [
    DobComponent,
    PaginationComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    matModules,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    PipesModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatChipsModule,
    CdkMenuModule,
    CommonModule,
    NgbModule,
    PermissionsModule,
    MatIconModule,
    MatStepperModule,
    MatTableModule,
    MatPaginatorModule,
    MatSliderModule,
    MatCheckboxModule,
    MatSortModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    matModules,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    PipesModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatChipsModule,
    NgbModule,
    MatPaginatorModule,
    PermissionsModule,
    MatIconModule,
    RouterModule,
    MatStepperModule,
    MatTableModule,
    MatSliderModule,
    MatExpansionModule,
    DobComponent,
    PaginationComponent,
    MatCheckboxModule,
    MatSortModule,
  ],
})
export class SharedModule {}
