import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpContext } from '@angular/common/http';
import { HIDE_LOADER } from '../interfaces/common.entities';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  public get(url: string, header?: any, hideLoader = false, responseType = 'json'): Observable<any> {
    setTimeout(() => {
      this.showLoader(hideLoader);
    }, 0);
    const options = {
      ...header,
      context: new HttpContext().set(HIDE_LOADER, hideLoader),
      responseType,
    };

    return this.http.get(url, options);
  }

  public post(url: string, body?: object, header?: any, hideLoader = false): Observable<any> {
    setTimeout(() => {
      this.showLoader(hideLoader);
    }, 0);
    const options = {
      ...header,
      context: new HttpContext().set(HIDE_LOADER, hideLoader),
    };

    return this.http.post(url, body, options);
  }

  public put(url: string, body: object, header?: any, hideLoader = false): Observable<any> {
    setTimeout(() => {
      this.showLoader(hideLoader);
    }, 0);
    const options = {
      ...header,
      context: new HttpContext().set(HIDE_LOADER, hideLoader),
    };

    return this.http.put(url, body, options);
  }

  public delete(url: string, header?: any, hideLoader = false): Observable<any> {
    setTimeout(() => {
      this.showLoader(hideLoader);
    }, 0);
    const options = {
      ...header,
      context: new HttpContext().set(HIDE_LOADER, hideLoader),
    };

    return this.http.delete(url, options);
  }

  public patch(url: string, body?: any, header?: object, hideLoader = false): Observable<any> {
    setTimeout(() => {
      this.showLoader(hideLoader);
    }, 0);
    const options = {
      ...header,
      context: new HttpContext().set(HIDE_LOADER, hideLoader),
    };

    return this.http.patch(url, body, options).pipe();
  }

  public subscribeToSSE(url: string, stopKey: string): Observable<string> {
    return new Observable((subscriber) => {
      const eventSource = new EventSource(url);
      eventSource.onmessage = (event: MessageEvent<string>) => {
        subscriber.next(event.data);
        if (event.data === stopKey) {
          eventSource.close();
          subscriber.complete();
        }
      };

      eventSource.onerror = () => {
        subscriber.error();
        if (eventSource) {
          eventSource.close();
        }
      };
    });
  }

  private showLoader(hideLoader: boolean): void {
    if (!hideLoader) {
      this.loader.next(true);
    }
  }
}
