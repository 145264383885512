import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hs-no-access',
  templateUrl: './hs-no-access.component.html',
  styleUrls: ['./hs-no-access.component.scss'],
})
export class HsNoAccessComponent {
  @Input() public cardTitle!: string;
  constructor() {}
}
