import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector:
    'input:not([type=checkbox])[formControlName], input:not([type=checkbox])[formControl],input:not([type=number])[formControl], input:not([type=checkbox])[ngModel]',
})
export class TrimDirective {
  constructor(private control: NgControl) {}
  @HostListener('keyup', ['$event'])
  onKeyUp() {
    if (isNaN(parseInt(this.control?.control?.value))) {
      this.control?.control?.setValue(
        this.control?.control?.value?.trim() === '' ? '' : this.control?.control?.value?.replace(/\s\s+/g, ''),
      );
    } else {
      if (typeof this.control?.control?.value !== 'number') {
        this.control?.control?.setValue(this.control?.control?.value?.trim());
      }
    }
  }
  @HostListener('blur', ['$event'])
  onBlur() {
    // console.log('called from blur')
    if (isNaN(this.control?.control?.value)) {
      this.control?.control?.setValue(this.control?.control?.value?.trim());
    }
  }
}
