import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RequestInterceptor } from './modules/shared/interceptors/request.interceptor';
import { ResponseInterceptor } from './modules/shared/interceptors/response.interceptor';
import { NotFoundComponent } from './modules/shared/component/not-found/not-found.component';
import { FooterComponent } from './modules/shared/component/footer/footer.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { TranslateService } from './modules/shared/services/translate.service';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './modules/shared/component/loader/loader.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from './modules/shared/shared.module';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DirectivesModule } from './modules/shared/directives/directive.module';
import { ClientLabelPipe } from './modules/shared/pipes/client-label.pipe';


export function setupTranslateFactory(service: TranslateService): unknown {
  return () => service.use('en');
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    FooterComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    MatDialogModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    AppRoutingModule,
    DirectivesModule,
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      maxOpened: 1,
      autoDismiss: true,
    }),
  ],
  providers: [
    TranslateService,
    ClientLabelPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true,
    },
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
